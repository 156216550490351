import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          button: link0 {
            url
            text
          }
          showSolutions: boolean0
          solutionsLink: link1 {
            url
            text
          }
          linkList {
            ... on Craft_linkList_link_BlockType {
              uid
              linkObject {
                url
                text
              }
              sublinks: footerSublinks {
                ... on Craft_footerSublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { linkList, button, showSolutions, solutionsLink } = globalSet;

  const nav = linkList.map(link => {
    return {
      uid: link.uid,
      text: link.linkObject.text,
      url: link.linkObject.url,
      sublinks: link.sublinks.map(l => ({
        uid: l.uid,
        ...l.sublink,
      })),
    };
  });

  return {
    nav,
    button,
    showSolutions,
    solutionsLink,
  };
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
