import React, { useState } from "react";
import { Icon, Container, Button } from "@atoms";
import { AppLink } from "@base";
import { MobileHeader, Logo, SolutionsDropdown } from "@molecules";
import { useAppState } from "@state";
import { m } from "framer-motion";
import useHeaderData from "@staticQueries/HeaderQuery";
import classNames from "classnames";

const Header = ({ theme }) => {
  const [{ layout }, dispatch] = useAppState();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const menuVariants = {
    hide: {
      y: "1rem",
      opacity: 0,
      pointerEvents: "none",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      y: 0,
      opacity: 1,
      pointerEvents: "auto",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  const background = {
    white: "bg-white",
    offwhite: "bg-offwhite",
    lightTeal: "bg-teal-light",
    lighterTeal: "bg-teal-lighter",
    darkTeal: "bg-teal-dark",
    darkerTeal: "bg-black",
  }[theme];

  const inverse = ["darkTeal", "darkerTeal"].includes(theme);

  // Mobile nav triggers
  const { hideNav } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "5",
    strokeLinecap: "round",
  };

  // Placeholder to be replaced with useHeaderData
  // or other static queries
  const { nav, button, showSolutions, solutionsLink } = useHeaderData();

  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={activeDropdown !== null ? { opacity: 0.9 } : { opacity: 0 }}
        className="pointer-events-none fixed inset-0 z-40 bg-black"
      />
      <header className="relative z-50">
        <div
          className={classNames(
            "relative z-50 flex h-16 w-full items-center py-2 duration-1000 sm:h-20 lg:h-24",
            background
          )}
        >
          <Container>
            <div className="relative flex items-center justify-between">
              <AppLink to="/" title="BridgeCare">
                <Logo />
              </AppLink>
              <nav aria-label="Header navigation">
                <ul className="hidden items-center gap-x-4 sm:gap-x-6 md:flex">
                  {showSolutions && (
                    <li
                      onMouseEnter={() => {
                        setActiveDropdown(0);
                      }}
                      onMouseLeave={() => {
                        setActiveDropdown(null);
                      }}
                      className="group/menu py-4"
                    >
                      <AppLink
                        to={solutionsLink.url}
                        onClick={() => setActiveDropdown(null)}
                        className={classNames(
                          "flex items-center gap-1.5 font-heading text-xs font-bold underline decoration-teal/0 decoration-2 underline-offset-[.25rem] duration-500 group-hover/menu:decoration-teal",
                          {
                            "text-white": inverse,
                          }
                        )}
                      >
                        <span>{solutionsLink.text}</span>
                        <Icon
                          name="chevron"
                          className={classNames(
                            "h-1.5 w-1.5 rotate-90 text-teal duration-500"
                          )}
                        />
                      </AppLink>
                      <m.div
                        initial="hide"
                        animate={activeDropdown === 0 ? "show" : "hide"}
                        variants={menuVariants}
                        className="absolute right-0 top-[100%] flex w-full max-w-3xl flex-col gap-3 gap-6 overflow-hidden whitespace-nowrap rounded-lg bg-white text-xs opacity-0 shadow-md lg:max-w-4xl"
                      >
                        <SolutionsDropdown
                          model={[activeDropdown, setActiveDropdown]}
                        />
                      </m.div>
                    </li>
                  )}
                  {nav?.map((link, i) => {
                    const { text, url, uid } = link;
                    return (
                      <li
                        onMouseEnter={() => {
                          if (link.sublinks?.length) setActiveDropdown(i + 1);
                        }}
                        onMouseLeave={() => {
                          if (link.sublinks?.length) setActiveDropdown(null);
                        }}
                        key={uid}
                        className="group/menu relative py-4"
                      >
                        <AppLink
                          to={url}
                          onClick={() => setActiveDropdown(null)}
                          className={classNames(
                            "flex items-center gap-1.5 font-heading text-xs font-bold underline decoration-teal/0 decoration-2 underline-offset-[.25rem] duration-500 group-hover/menu:decoration-teal",
                            {
                              "text-white": inverse,
                            }
                          )}
                        >
                          <span>{text}</span>
                          {!!link.sublinks?.length && (
                            <Icon
                              name="chevron"
                              className={classNames(
                                "h-1.5 w-1.5 rotate-90 text-teal duration-500"
                              )}
                            />
                          )}
                        </AppLink>
                        {!!link.sublinks?.length && (
                          <m.ul
                            initial="hide"
                            animate={activeDropdown === i + 1 ? "show" : "hide"}
                            variants={menuVariants}
                            className="absolute top-[100%] flex translate-y-4 flex-col gap-3 overflow-hidden whitespace-nowrap rounded-lg bg-white p-4 font-body text-xs text-black opacity-0 shadow"
                          >
                            {link.sublinks.map(l => (
                              <li key={l.uid}>
                                <AppLink
                                  className="font-heading underline decoration-teal/0 decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-teal"
                                  to={l.url}
                                  onClick={() => setActiveDropdown(null)}
                                >
                                  {l.text}
                                </AppLink>
                              </li>
                            ))}
                          </m.ul>
                        )}
                      </li>
                    );
                  })}
                  {button?.url && (
                    <li>
                      <Button size="sm" to={button.url}>
                        {button.text}
                      </Button>
                    </li>
                  )}
                </ul>
                <button
                  type="button"
                  className="flex md:hidden"
                  aria-label="Toggle mobile navigation"
                  onClick={() => {
                    dispatch({
                      type: hideNav ? "showNav" : "hideNav",
                    });
                  }}
                >
                  {/* Hamburger Menu with animated SVG */}
                  <span
                    className={classNames("svg-icon h-4 w-4", {
                      "text-black":
                        theme !== "darkerTeal" || theme !== "darkTeal",
                      "text-teal-light":
                        theme === "darkerTeal" || theme === "darkTeal",
                    })}
                  >
                    <svg viewBox="0 0 27 24">
                      <m.path
                        {...iconStyles}
                        variants={{
                          closed: { d: "M32 2L2 2" },
                          open: { d: "M24 22L2 2" },
                        }}
                      />
                      <m.path
                        {...iconStyles}
                        d="M32 12L2 12"
                        variants={{
                          closed: { opacity: 1 },
                          open: { opacity: 0 },
                        }}
                        transition={{ duration: 0.2 }}
                      />
                      <m.path
                        {...iconStyles}
                        variants={{
                          closed: { d: "M32 22L2 22" },
                          open: { d: "M24 2L2 22" },
                        }}
                      />
                    </svg>
                  </span>
                </button>
              </nav>
            </div>
          </Container>
        </div>
        <MobileHeader
          nav={nav}
          button={button}
          showSolutions={showSolutions}
          solutionsLink={solutionsLink}
          theme={theme}
        />
      </header>
    </>
  );
};

Header.defaultProps = {};

export default Header;
